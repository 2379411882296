import React from 'react'
import Navigation from '../Navigation/Navigation'
import SushisMaker from '../SushisMaker/SushisMaker'
import UserContext from '../User/User'
import './App.css'

const user = {
  name: 'Felix',
  favorites: [
    'avocado',
    'octopus'
  ]
}

function App() {
  return <UserContext.Provider value={user}>
    <Navigation />
    <SushisMaker />
  </UserContext.Provider>
}

export default App
