import React from 'react'
import SushisItem from '../SushisItem/SushisItem'

import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: [10, 50],
    justifyContent: 'center',
  }
})

const ingredients = [
  {
    image: '🍚',
    name: 'seasoned rice',
  },
  {
    image: '🐟',
    name: 'fish',
  },
  {
    image: '𓆟',
    name: 'salmon',
  },
  {
    image: '🐙',
    name: 'octopus',
  },
  {
    image: '🦐',
    name: 'shrimp',
  },
  {
    image: '🥑',
    name: 'avocado',
  },
  {
    image: '🥕',
    name: 'carrot',
  },
  {
    image: '🥒',
    name: 'cucumber',
  },
  {
    image: '🦞',
    name: 'lobster',
  },
  {
    image: '🦀',
    name: 'crab',
  },
  {
    image: '🥭',
    name: 'mango',
  },
  {
    image: '🍢',
    name: 'oden',
  },
  {
    image: '🍙',
    name: 'rice bowl',
  },
  {
    image: '🍘',
    name: 'rice cracker',
  },
  {
    image: '🧈',
    name: 'tofu',
  },
  {
    image: '🌿',
    name: 'seaweed',
  }
]

export default function SushisBuilder() {
  const classes = useStyles()
  return(
    <div className={classes.wrapper}>
      {
        ingredients.map(ingredient => (
          <SushisItem
            key={ingredient.name}
            image={ingredient.image}
            name={ingredient.name}
          />
        ))
      }
    </div>
  )
}