import React, { useReducer, createContext } from 'react'
import { createUseStyles } from 'react-jss'
import SushisBuilder from '../SushisBuilder/SushisBuilder'
import SushisSummary from '../SushisSummary/SushisSummary'

const useStyles = createUseStyles({
  wrapper: {
    textAlign: 'center',
  }
})

export const SushisContext = createContext()

function reducer(state, item) {
  return [...state, item]
}

export default function SushisMaker() {
  const classes = useStyles()
  const [sushis, setSushis] = useReducer(reducer, [])
  return(
    <SushisContext.Provider value={{ sushis, setSushis }}>
      <h1 className={classes.wrapper}>
        <span role="img" aria-label="sushis">🍣 </span>
          Build Your Custom Sushis!
          <span role="img" aria-label="sushis"> 🍣</span>
      </h1>
      <SushisBuilder />
      <SushisSummary />
    </SushisContext.Provider>
  )
}